export const PHONES_FRAGMENT = {
  name: "phones",
  entry: `
    fragment phones on Shopper {
      phones {
        id
        isRemovable
        isValidated
        phone
        validationStartedAt
        validationExpired
      }
    }`
};

export const EMAILS_FRAGMENT = {
  name: "emails",
  entry: `
    fragment emails on Shopper {
      emails {
        id
        isRemovable
        isValidated
        email
        validationStartedAt
        validationExpired
      }
    }`
};

export const LAST_USED_STORES = {
  name: "lastUsedStores",
  entry: `
    fragment lastUsedStores on Shopper {
      lastUsedStores {
        logo
        url
        name
      }
    }`
};

export const CARD_REFERENCES_FRAGMENT = {
  name: "cardReferences",
  entry: `
    fragment cardReferences on Shopper {
      cardReferences {
        id
        expiryDate
        expiryMonth
        expiryYear
        isExpired
        isExpiringSoon
        isDefault
        maskedPan
        lastFour
        issuer
        cardFingerprint
      }
    }`
};

export const ADDRESSES_FRAGMENT = {
  name: "addresses",
  entry: `
    fragment addresses on Shopper {
      addresses {
        id
        addressLine
        addressLine1
        addressLine2
        city
        postalCode
        state
      }
    }`
};

export const SHOPPER_INFO_FRAGMENT = {
  name: "shopperInfo",
  entry: `
    fragment shopperInfo on Shopper {
      id
      identifier
      nin
      givenNames
      activated
      mandatoryDocumentationRequestStatus
      uploaderUrl
      surnames
      hasAutoPaymentEnabled
      dateOfBirth
      createdAt
      showPromotions
      locale
      realm
      intercomAuthenticationKey
      primaryEmail
      primaryPhoneInternational
      segmentation {
        key
        value
      }
      referralProgram {
        featureFlagEnabled,
        enabledToBeHost,
        guests {
          givenNames
        }
      }
      paymentProtectionInsurance {
        visible
      }
    }`
};

export const ORDER_INFO_FRAGMENT = {
  name: "orderInfo",
  entry: `
    fragment orderInfo on Order {
      id
      confirmedAt
      shippedAt
      currencySymbol
      currentOrderValue
      originalOrderValue
      currentProductName
      currentProductTitle
      originalProductName
      unchargedOrderValueForInvoicingOrders
      isFrozen
      primaryReference
      secondaryReference
      reference
      hasAutoPaymentDisabled
      hasForcedCard
      discountAppliedAmount
      sequraContact {
        reference
        phone
        phonePrefix
        contactHours
        url
      }
    }`
};

export const UNSHIPPED_CART_FRAGMENT = {
  name: "unshippedCart",
  entry: `
    fragment unshippedCart on Order {
      unshippedCart {
        totalWithTax
        items {
          name
          reference
          type
          priceWithTax
          quantity
          totalWithTax
        }
      }
    }`
};

export const SHIPPED_CART_FRAGMENT = {
  name: "shippedCart",
  entry: `
    fragment shippedCart on Order {
      shippedCart {
        totalWithTax
        items {
          name
          reference
          type
          priceWithTax
          quantity
          totalWithTax
        }
      }
    }`
};

export const MERCHANT_FRAGMENT = {
  name: "merchant",
  entry: `
  fragment merchant on Order {
    merchant {
      id
      logo
      name
      reference
      supportEmail
      supportPhone
      url
      invoicingCreditDays
      returnDelayDays
    }
  }`
};

export const INVOICES_FRAGMENT = {
  name: "invoices",
  entry: `
    fragment invoices on Order {
      invoices {
        id
        number
        shippedAt
        invoicedOn
        dueOn
        isOverdue
        paidOn
        invoiceAmount
        unpaidAmount
        paidAmount
        paymentStatus
        returned
        canBeReturned
        cart {
          totalWithTax
          items {
            name
            reference
            type
            priceWithTax
            quantity
            totalWithTax
          }
        }
      }
    }`
};

export const PAYMENT_PLAN_FRAGMENT = {
  name: "paymentPlan",
  entry: `
    fragment paymentPlan on Order {
      paymentPlan {
        id
        number
        unchargedOrderValue
        instalmentAmount
        instalmentFee
        agreedAmount
        balance
        nextChargeDate
        instalments {
          instalmentNumber
          instalmentAmount
          instalmentTotal
          instalmentFee
          lateFeesAmount
          lateInterestFeesAmount
          paidAmount
          unpaidAmount
          paymentStatus
          dueOn
          isOverdue
          paidOn
          accountEvents {
            amount
            bank
            createdAt
            id
            paymentMethod
            type
            cardReference {
              id
              expiryDate
              expiryMonth
              expiryYear
              isExpired
              isExpiringSoon
              isDefault
              maskedPan
              lastFour
              issuer
            }
          }
        }
        pastInstalments {
          instalmentNumber
          instalmentAmount
          instalmentTotal
          instalmentFee
          lateFeesAmount
          lateInterestFeesAmount
          paidAmount
          unpaidAmount
          paymentStatus
          dueOn
          isOverdue
          paidOn
          accountEvents {
            amount
            bank
            createdAt
            id
            paymentMethod
            type
            cardReference {
              id
              expiryDate
              expiryMonth
              expiryYear
              isExpired
              isExpiringSoon
              isDefault
              maskedPan
              lastFour
              issuer
            }
          }
        }
        futureInstalments {
          instalmentNumber
          instalmentAmount
          instalmentTotal
          instalmentFee
          lateFeesAmount
          lateInterestFeesAmount
          paidAmount
          unpaidAmount
          paymentStatus
          dueOn
          isOverdue
          paidOn
          accountEvents {
            amount
            bank
            createdAt
            id
            paymentMethod
            type
            cardReference {
              id
              expiryDate
              expiryMonth
              expiryYear
              isExpired
              isExpiringSoon
              isDefault
              maskedPan
              lastFour
              issuer
            }
          }
        }
      }
    }`
};

export const RENTING_PLAN_FRAGMENT = {
  name: "rentingPlan",
  entry: `
    fragment rentingPlan on Order {
      rentingPlan {
        id
        number
        unchargedOrderValue
        balance
        nextChargeDate
        monthCount
        monthlyTotal
        pastCharges {
          amount
          dueOn
          isOverdue
          lateInterestFeesAmount
          number
          paidAmount
          paidOn
          paymentStatus
          totalCharges
          unpaidAmount
          accountEvents {
            amount
            bank
            createdAt
            id
            paymentMethod
            type
            cardReference {
              id
              expiryDate
              expiryMonth
              expiryYear
              isExpired
              isExpiringSoon
              isDefault
              maskedPan
              lastFour
              issuer
            }
          }
        }
        futureCharges {
          amount
          dueOn
          isOverdue
          lateInterestFeesAmount
          number
          paidAmount
          paidOn
          paymentStatus
          totalCharges
          unpaidAmount
          accountEvents {
            amount
            bank
            createdAt
            id
            paymentMethod
            type
            cardReference {
              id
              expiryDate
              expiryMonth
              expiryYear
              isExpired
              isExpiringSoon
              isDefault
              maskedPan
              lastFour
              issuer
            }
          }
        }
      }
    }`
};

export const CARD_REFERENCE_FRAGMENT = {
  name: "cardReference",
  entry: `
    fragment cardReference on Order {
      cardReference {
        id
        expiryDate
        expiryMonth
        expiryYear
        isExpired
        isExpiringSoon
        isDefault
        maskedPan
        lastFour
        issuer,
        cardFingerprint
      }
    }`
};

export const CONVERSION_PLANS_FRAGMENT = {
  name: "conversionPlans",
  entry: `
    fragment conversionPlans on Order {
      conversionPlans {
        product
        instalmentCount
        instalmentAmount
        instalmentFee
        instalmentTotal
        downPaymentAmount
        downPaymentFees
        downPaymentTotal
        setupFee
        apr
        interest
        totalWithTax
        costOfCredit
        costOfCreditPct
        grandTotal
        default
        generalConditionsUrl
        privacyPolicyUrl
        secciUrl
        futureInstalments {
          instalmentAmount
          instalmentTotal
          instalmentFee
          dueOn
        }
      }
    }`
};

export const REFUND_PROCESS_FRAGMENT = {
  name: "refundProcess",
  entry: `
    fragment refundProcess on Order {
      refundProcess {
        id
        amount
        state
      }
    }
  `
};

export const AMORTIZATION_FRAGMENT = {
  name: "amortization",
  entry: `
    fragment amortization on Order {
      amortization {
        total
        amount
        fee
        savedFees
        cardPayment {
          url
        }
        bankTransferPayment {
          beneficiary
          reference
          bankAccounts {
            reference
            name
            iban
          }
        }
      }
    }
  `
};

export const PSD2_CARD_VERIFICATION_FRAGMENT = {
  name: "psd2CardVerification",
  entry: `
    fragment psd2CardVerification on Order {
      psd2CardVerification {
        verified
        cardReference {
          id
          lastFour
          issuer
          expiryDate
          expiryMonth
          expiryYear
          cardFingerprint
        }
      }
    }
  `
};

export const REFERRAL_PROGRAM_FRAGMENT = {
  name: "referralProgram",
  entry: `
    fragment referralProgram on Shopper {
      referralProgram {
        featureFlagEnabled,
        enabledToBeHost,
        guests {
          givenNames
          identifier,
          pendingDays,
          voucherAdded
        }
      }
    }`
};

export const GENERAL_VOUCHERS_FRAGMENT = {
  name: "generalVouchers",
  entry: `
    fragment generalVouchers on Shopper {
      generalVouchers {
        balance
        visible
      }
    }`
};

export const BANK_TRANSFER_PAYMENT = {
  name: "bankTransferPayment",
  entry: `
    fragment bankTransferPayment on Order {
      bankTransferPayment {
        beneficiary
        reference
        bankAccounts {
          reference
          name
          iban
        }
      }
    }
  `
};

export const MERCHANTS_PROMOTIONS_FRAGMENT = {
  name: "merchantsPromotions",
  entry: `
    fragment merchantsPromotions on Shopper {
      merchantsPromotions {
        promotionsAllowed
        availablePromotions
        promotedMerchants {
          reference
          url
          name
          description
          sector
          logo
          backgroundImage
          products
          promotion {
            reference
            title
            description
            url
            backgroundImage
            lastDate
            expiration
          }
          stores {
            findStoresUrl
          }
        }
      }
    }
  `
};

export const PAYMENT_PROTECTION_INSURANCE_FRAGMENT = {
  name: "paymentProtectionInsurance",
  entry: `
    fragment paymentProtectionInsurance on Shopper {
      paymentProtectionInsurance {
        visible
        locale
        shopperPublicToken
      }
    }`
};
