import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { PageTemplate } from "../../templates";
import locales from "../../locales";
import usePaymentProtectionInsuranceProgram from "./hooks/usePaymentProtectionInsurance";

const PaymentProtectionInsurance = () => {
  const intl = useIntl();

  const { locale, shopperPublicToken } = usePaymentProtectionInsuranceProgram();

  useEffect(() => {
    const displayWeecoverWidget = ({ retry = false }) => {
      if (window.weecoverWidget) {
        const {
          render: renderWeecoverWidget
        } = window.weecoverWidget.default.widget("weecover_widget");
        renderWeecoverWidget(locale, shopperPublicToken);
      } else if (retry) {
        /* We believe there's a race condition issue:
          sometimes the WeeCover widget script loads slower than our graphl hook response.
          That causes the `window.weecoverWidget` to not be present when we try to use it.
          As we weren't able to listen to `widget:loaded` WeeCover event as a proof that the Widget is loaded,
          we put a timeout to retry instead.
        */
        setTimeout(() => {
          displayWeecoverWidget({ retry: false });
        }, 250);
      }
    };

    const weecoverScriptId = "weecover-widget-script";
    if (!document.getElementById(weecoverScriptId)) {
      const script = document.createElement("script");
      script.id = weecoverScriptId;
      script.src = window.env.weecover.widgetScriptUrl;
      document.body.appendChild(script);
    } else if (locale && shopperPublicToken) {
      displayWeecoverWidget({ retry: true });
    }
  }, [locale, shopperPublicToken]);

  return (
    <PageTemplate
      title={intl.formatMessage(
        locales.payment_protection_insurance_page_title
      )}
    >
      <div id="weecover_widget" />
    </PageTemplate>
  );
};

export default PaymentProtectionInsurance;
